import { useState, useEffect, useRef } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  Card,
  Checkbox,
  DataTable,
  DatePicker,
  Frame,
  Icon,
  Pagination,
  Popover,
  RangeSlider,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";
import { SearchMinor, CalendarMinor } from "@shopify/polaris-icons";

import * as moment from "moment";

import { useSettings } from "../../../AppContext";

import {
  TRANSACTIONS_SORT_FIELDS,
  TRANSACTION_TYPE,
  BANNER_STATUS,
} from "./constants";

function Transactions() {
  const { customerId, routePrefix } = useSettings();
  const debounceTimeout = 1000;
  const daysToLoad = 2;

  const [status, setStatus] = useState(false);
  const [msg, setMsg] = useState("");
  const [toastContent, setToastContent] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status = "critical", title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const [transactions, setTransactions] = useState([]);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState();

  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const getDataTableSource = (list) => {
    const lst = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      item.credit = null;
      item.adjustment = null;
      item.discountCode = null;
      item.order = null;
      item.refund = null;

      if (item.transactionType === TRANSACTION_TYPE.CREDIT) {
        item.credit = `$${item.amount.toFixed(2)}`;
      } else if (item.transactionType === TRANSACTION_TYPE.ADJUSTMENT) {
        item.adjustment = `-$${item.amount.toFixed(2)}`;
      } else if (item.transactionType === TRANSACTION_TYPE.DISCOUNT_CODE) {
        item.discountCode = `-$${item.amount.toFixed(2)}`;
      } else if (item.transactionType === TRANSACTION_TYPE.ORDER) {
        item.order = `-$${item.amount.toFixed(2)}`;
      } else if (item.transactionType === TRANSACTION_TYPE.REFUND) {
        item.refund = `$${item.amount.toFixed(2)}`;
      }

      lst.push([
        moment(item.createdDate).utc().format("MM/DD/YYYY"),
        item.customerName,
        item.customerEmail,
        item.credit,
        item.adjustment,
        item.discountCode,
        item.order,
        item.refund,
        item.reference,
        item.expirationDate
          ? moment(item.expirationDate).utc().format("MM/DD/YYYY")
          : "",
        item.adminName ? item.adminName : item.adminEmail,
      ]);
    }

    return lst;
  };

  const rangeInitialValue = [0, 0];

  const [rangesLimits, setRangesLimits] = useState({
    creditAmount: 0,
    adjustmentAmount: 0,
    discountCodeAmount: 0,
    orderAmount: 0,
    refundAmount: 0,
  });

  const [query, setQuery] = useState({
    search: "",
    startDate: "",
    endDate: "",
    customerName: "",
    customerEmail: "",
    creditAmount: rangeInitialValue,
    adjustmentAmount: rangeInitialValue,
    discountCodeAmount: rangeInitialValue,
    orderAmount: rangeInitialValue,
    refundAmount: rangeInitialValue,
    createdByName: "",
    createdByEmail: "",
    sortField: "createdDate",
    sortOrder: "desc",
    page: 1,
    pageSize: 10,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      let addProp = false;
      const rangeCheckboxKeys = Object.keys(rangeCheckbox);

      if (rangeCheckboxKeys.includes(key)) {
        if (rangeCheckbox[key]) {
          addProp = true;
        }
      } else {
        addProp = true;
      }

      if (addProp) {
        params.push(`${key}=${encodeURIComponent(query[key])}`);
      }
    }

    return params;
  };

  const queryParamsForExport = () => {
    const params = {};
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      const rangeCheckboxKeys = Object.keys(rangeCheckbox);

      if (rangeCheckboxKeys.includes(key)) {
        if (rangeCheckbox[key]) {
          params[key] = query[key].join(",");
        }
      } else {
        params[key] = query[key];
      }
    }

    delete params.page;
    delete params.pageSize;

    return params;
  };

  const getTransactions = async () => {
    const params = queryParams();

    const url = `/credits/transactions?${params.join("&")}`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, hasPrevious, hasNext, total, rangesLimits } = data;

        setStatus(status);
        setMsg(msg);
        setHasPrevious(hasPrevious);
        setHasNext(hasNext);
        setRangesLimits(rangesLimits);

        const totalPages = Math.ceil(total / query.pageSize);
        setTotal(totalPages);

        let list = data.list;

        if (!list || list.length === 0) {
          setTransactions([]);
          setStatus(true);
          setMsg(`No records found.`);
          setShowToast(true);

          return;
        }

        const lst = getDataTableSource(list);
        setTransactions(lst);
      } else {
        setTransactions([]);
        setStatus(false);
        setMsg(`No records found.`);
      }

      setShowToast(true);
    } catch (err) {
      console.log(err);

      setTransactions([]);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Customers update",
        `Error getting transactions`
      );
    }
  };

  const searchChanged = (value) => {
    setQuery({
      ...query,
      page: 1,
      search: value,
    });
  };

  useEffect(() => {
    debounce(getTransactions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    let content = "";

    if (!status) {
      content = "Error: ";
    }

    content = `${content} ${msg}`;
    setToastContent(content);
  }, [status, msg]);

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const search = () => getTransactions();

  const exportTransactions = async () => {
    const params = queryParamsForExport();

    const data = {
      admin: customerId,
      ...params,
    };

    const url = `/api/credits/transactions/export`;

    try {
      // RM stringify
      const response = await axios.post(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        setStatus(status);
        setMsg(msg);
        setShowToast(true);
      }
    } catch (err) {
      console.log(err);

      let msg = "";
      const data = err.response?.data;

      if (data) {
        if (data.errMsg) {
          msg = data.errMsg;
        } else if (data.msg) {
          msg = data.msg;
        }
      }

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Data validation",
        `Error exporting transactions(s): ${err}`
      );
    }
  };

  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);

  useEffect(() => {
    const list = TRANSACTIONS_SORT_FIELDS.map((item) => {
      return {
        label: item.title,
        value: item.id,
      };
    });

    setSortOptions(list);

    const found = TRANSACTIONS_SORT_FIELDS.find(
      (item) => item.field === query.sortField && item.order === query.sortOrder
    );

    if (found) {
      setSelectedSort(found.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortChanged = (option) => {
    setSelectedSort(option);

    const found = TRANSACTIONS_SORT_FIELDS.find(
      (item) => item.id === parseInt(option)
    );
    console.log("found:", found);

    setQuery({
      ...query,
      sortField: found.field,
      sortOrder: found.order,
    });
  };

  const columnContentTypes = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
  ];

  const headings = [
    "Date",
    "Customer",
    "Email",
    TRANSACTION_TYPE.CREDIT,
    TRANSACTION_TYPE.ADJUSTMENT,
    TRANSACTION_TYPE.DISCOUNT_CODE,
    TRANSACTION_TYPE.ORDER,
    TRANSACTION_TYPE.REFUND,
    "Reference",
    "Expire On",
    "Created By",
  ];

  // Advanced search

  const [searchForm, setSearchForm] = useState(false);

  const toggleSearchForm = () => setSearchForm((searchForm) => !searchForm);

  const prefix = "$";
  const min = 0;
  const step = 5;

  const [rangeCheckbox, setRangeCheckbox] = useState({
    creditAmount: false,
    adjustmentAmount: false,
    discountCodeAmount: false,
    orderAmount: false,
    refundAmount: false,
  });

  const rangeCheckboxChanged = (field, value) => {
    setRangeCheckbox({
      ...rangeCheckbox,
      page: 1,
      [field]: value,
    });

    if (!value) {
      setQuery({
        ...query,
        [field]: rangeInitialValue,
      });
    }
  };

  const rangeSliderChanged = (field, value) => {
    setQuery({
      ...query,
      page: 1,
      [field]: value,
    });
  };

  const textFieldChanged = (field, value) => {
    setQuery({
      ...query,
      page: 1,
      [field]: value,
    });
  };

  // Start date picker

  const [startDateVisible, setStartDateVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: selectedStartDate.getMonth(),
    startYear: selectedStartDate.getFullYear(),
  });

  const formattedValue = (selectedDate) =>
    selectedDate.toISOString().slice(0, 10);

  function startDateOnClose({ relatedTarget }) {
    setStartDateVisible(false);
  }

  function startDateMonthChanged(month, year) {
    setStartDate({ startMonth: month, startYear: year });
  }

  function startDateChanged({ end: newSelectedDate }) {
    setSelectedStartDate(newSelectedDate);
    setStartDateVisible(false);

    setQuery({
      ...query,
      page: 1,
    });
  }

  useEffect(() => {
    if (selectedStartDate) {
      setStartDate({
        startMonth: selectedStartDate.getMonth(),
        startYear: selectedStartDate.getFullYear(),
      });

      setQuery({
        ...query,
        page: 1,
        startDate: moment(selectedStartDate).utc().format("MM/DD/YYYY"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate]);

  // End date picker

  const [endDateVisible, setEndDateVisible] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [{ endMonth, endYear }, setEndDate] = useState({
    endMonth: selectedEndDate.getMonth(),
    endYear: selectedEndDate.getFullYear(),
  });

  function endDateOnClose({ relatedTarget }) {
    setEndDateVisible(false);
  }

  function endDateMonthChanged(month, year) {
    setEndDate({ endMonth: month, endYear: year });
  }

  function endDateChanged({ end: newSelectedDate }) {
    setSelectedEndDate(newSelectedDate);
    setEndDateVisible(false);

    setQuery({
      ...query,
      page: 1,
    });
  }

  useEffect(() => {
    if (selectedEndDate) {
      setEndDate({
        endMonth: selectedEndDate.getMonth(),
        endYear: selectedEndDate.getFullYear(),
      });

      setQuery({
        ...query,
        page: 1,
        endDate: moment(selectedEndDate).utc().format("MM/DD/YYYY"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);

  useEffect(() => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - daysToLoad);

    setSelectedStartDate(startDate);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    setSelectedEndDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          paddingLeft: "10px",
          paddingTop: "10px",
          paddingRight: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ minWidth: "315px" }}>
          <TextField
            placeholder="Search Transactions"
            value={query.search}
            onChange={searchChanged}
            autoComplete="off"
            prefix={<Icon source={SearchMinor} color="base" />}
            alignItems="end"
          />
        </div>
        <div style={{ marginLeft: 5 }}>
          <Button onClick={search}>Search</Button>
        </div>

        <div style={{ marginLeft: 5, minWidth: "150px" }}>
          <Button primary fullWidth onClick={toggleSearchForm}>
            Advanced Search
          </Button>
        </div>

        <div style={{ marginLeft: 5, minWidth: "130px" }}>
          <Button
            primary
            fullWidth
            disabled={!transactions || transactions.length === 0}
            onClick={exportTransactions}
          >
            Export Filtered
          </Button>
        </div>

        <div style={{ width: "100%" }}></div>

        <div style={{ marginLeft: 30 }}>
          <Select
            label=""
            placeholder="Sort By"
            options={sortOptions}
            onChange={sortChanged}
            value={selectedSort}
          />
        </div>
        {total > 0 && (
          <label
            style={{
              minWidth: "90px",
              textAlign: "right",
              paddingLeft: "10px",
              paddingTop: "8px",
            }}
          >
            {query.page} of {total}
          </label>
        )}
        <div style={{ marginLeft: 10 }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQuery({
                ...query,
                page: query.page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQuery({
                ...query,
                page: query.page + 1,
              });
            }}
          />
        </div>
      </div>

      {searchForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <Card>
            <div
              style={{
                padding: "10px",
              }}
            >
              <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                Advanced Search
              </label>

              <div style={{ display: "flex", padding: "10px" }}>
                <div style={{ width: "25%", paddingRight: "20px" }}>
                  <div style={{ paddingBottom: "10px" }}>
                    <Popover
                      active={startDateVisible}
                      autofocusTarget="none"
                      preferredAlignment="left"
                      fullWidth
                      preferInputActivator={false}
                      preferredPosition="below"
                      preventCloseOnChildOverlayClick
                      onClose={startDateOnClose}
                      activator={
                        <TextField
                          role="combobox"
                          label={"Start date"}
                          prefix={<Icon source={CalendarMinor} />}
                          value={formattedValue(selectedStartDate)}
                          onFocus={() => setStartDateVisible(true)}
                          autoComplete="off"
                        />
                      }
                    >
                      <Card>
                        <DatePicker
                          month={startMonth}
                          year={startYear}
                          selected={selectedStartDate}
                          onMonthChange={startDateMonthChanged}
                          onChange={startDateChanged}
                        />
                      </Card>
                    </Popover>
                  </div>

                  <div style={{ paddingBottom: "10px" }}>
                    <Popover
                      active={endDateVisible}
                      autofocusTarget="none"
                      preferredAlignment="left"
                      fullWidth
                      preferInputActivator={false}
                      preferredPosition="below"
                      preventCloseOnChildOverlayClick
                      onClose={endDateOnClose}
                      activator={
                        <TextField
                          role="combobox"
                          label={"End date"}
                          prefix={<Icon source={CalendarMinor} />}
                          value={formattedValue(selectedEndDate)}
                          onFocus={() => setEndDateVisible(true)}
                          autoComplete="off"
                        />
                      }
                    >
                      <Card>
                        <DatePicker
                          month={endMonth}
                          year={endYear}
                          selected={selectedEndDate}
                          onMonthChange={endDateMonthChanged}
                          onChange={endDateChanged}
                        />
                      </Card>
                    </Popover>
                  </div>

                  <div>
                    <TextField
                      value={query.customerName}
                      onChange={(e) => textFieldChanged("customerName", e)}
                      label="Customer Name"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <TextField
                      value={query.customerEmail}
                      onChange={(e) => textFieldChanged("customerEmail", e)}
                      label="Email"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div style={{ width: "50%", textAlign: "center" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "5%", marginTop: "22px" }}>
                      <Checkbox
                        label=""
                        checked={rangeCheckbox.creditAmount}
                        onChange={(e) =>
                          rangeCheckboxChanged("creditAmount", e)
                        }
                      />
                    </div>

                    <div style={{ width: "65%", paddingRight: "20px" }}>
                      <div>
                        <RangeSlider
                          output
                          label="Credit amount"
                          value={query.creditAmount}
                          min={min}
                          max={rangesLimits.creditAmount}
                          step={step}
                          disabled={!rangeCheckbox.creditAmount}
                          onChange={(e) =>
                            rangeSliderChanged("creditAmount", e)
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", textAlign: "left" }}>
                      <div>
                        Min: {prefix}
                        {query.creditAmount[0]}
                      </div>
                      <div>
                        Max: {prefix}
                        {query.creditAmount[1]}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ width: "5%", marginTop: "22px" }}>
                      <Checkbox
                        label=""
                        checked={rangeCheckbox.adjustmentAmount}
                        onChange={(e) =>
                          rangeCheckboxChanged("adjustmentAmount", e)
                        }
                      />
                    </div>

                    <div style={{ width: "65%", paddingRight: "20px" }}>
                      <div>
                        <RangeSlider
                          output
                          label="Adjustment amount"
                          value={query.adjustmentAmount}
                          min={min}
                          max={rangesLimits.adjustmentAmount}
                          step={step}
                          disabled={!rangeCheckbox.adjustmentAmount}
                          onChange={(e) =>
                            rangeSliderChanged("adjustmentAmount", e)
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", textAlign: "left" }}>
                      <div>
                        Min: {prefix}
                        {query.adjustmentAmount[0]}
                      </div>
                      <div>
                        Max: {prefix}
                        {query.adjustmentAmount[1]}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ width: "5%", marginTop: "22px" }}>
                      <Checkbox
                        label=""
                        checked={rangeCheckbox.discountCodeAmount}
                        onChange={(e) =>
                          rangeCheckboxChanged("discountCodeAmount", e)
                        }
                      />
                    </div>

                    <div style={{ width: "65%", paddingRight: "20px" }}>
                      <div>
                        <RangeSlider
                          output
                          label="Discount Code amount"
                          value={query.discountCodeAmount}
                          min={min}
                          max={rangesLimits.discountCodeAmount}
                          step={step}
                          disabled={!rangeCheckbox.discountCodeAmount}
                          onChange={(e) =>
                            rangeSliderChanged("discountCodeAmount", e)
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", textAlign: "left" }}>
                      <div>
                        Min: {prefix}
                        {query.discountCodeAmount[0]}
                      </div>
                      <div>
                        Max: {prefix}
                        {query.discountCodeAmount[1]}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ width: "5%", marginTop: "22px" }}>
                      <Checkbox
                        label=""
                        checked={rangeCheckbox.orderAmount}
                        onChange={(e) => rangeCheckboxChanged("orderAmount", e)}
                      />
                    </div>

                    <div style={{ width: "65%", paddingRight: "20px" }}>
                      <div>
                        <RangeSlider
                          output
                          label="Order amount"
                          value={query.orderAmount}
                          min={min}
                          max={rangesLimits.orderAmount}
                          step={step}
                          disabled={!rangeCheckbox.orderAmount}
                          onChange={(e) => rangeSliderChanged("orderAmount", e)}
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", textAlign: "left" }}>
                      <div>
                        Min: {prefix}
                        {query.orderAmount[0]}
                      </div>
                      <div>
                        Max: {prefix}
                        {query.orderAmount[1]}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ width: "5%", marginTop: "22px" }}>
                      <Checkbox
                        label=""
                        checked={rangeCheckbox.refundAmount}
                        onChange={(e) =>
                          rangeCheckboxChanged("refundAmount", e)
                        }
                      />
                    </div>

                    <div style={{ width: "65%", paddingRight: "20px" }}>
                      <div>
                        <RangeSlider
                          output
                          label="Refund amount"
                          value={query.refundAmount}
                          min={min}
                          max={rangesLimits.refundAmount}
                          step={step}
                          disabled={!rangeCheckbox.refundAmount}
                          onChange={(e) =>
                            rangeSliderChanged("refundAmount", e)
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", textAlign: "left" }}>
                      <div>
                        Min: {prefix}
                        {query.refundAmount[0]}
                      </div>
                      <div>
                        Max: {prefix}
                        {query.refundAmount[1]}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ width: "25%", paddingLeft: "10px" }}>
                  <div>
                    <TextField
                      value={query.createdByName}
                      onChange={(e) => textFieldChanged("createdByName", e)}
                      label="Created By Name"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <TextField
                      value={query.createdByEmail}
                      onChange={(e) => textFieldChanged("createdByEmail", e)}
                      label="Email"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}

      {transactions && (
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={transactions}
        />
      )}

      {showToast && <Toast content={toastContent} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Transactions;
