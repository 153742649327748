import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import Invited from "./components/inviteusers/Invited";
import Registered from "./components/registeredusers/registered";
import store from "store-js";

export default function Inviteusers({ sitename }) {
  const currentTab = store.get("staffTab") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("staffTab", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Invited",
      title: "Invited",
      content: "Invited",
      page: <Invited />,
      panelID: "Invited",
    },
    {
      id: "Registered",
      title: "Registered",
      content: "Registered",
      page: <Registered />,
      panelID: "Registered",
    },
  ];

  return (
    <Page
      breadcrumbs={[{ url: "/" }]}
      title={sitename + " " + tabs[selected].id + " Users"}
      fullWidth
    >
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
