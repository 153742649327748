import React, { useState, useRef } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Spinner } from "react-bootstrap";
import { TextField, Icon } from "@shopify/polaris";
import { ImageMajor } from "@shopify/polaris-icons";
const moment = require("moment-timezone");

function MediaFiles() {

  const [video, setVideo] = useState({});
  const [image, setImage] = useState({});
  const [videoAlt, setVideoAlt] = useState({});
  const [imageAlt, setImageAlt] = useState({});
  const [mediaData, setMediaData] = useState({});

  const [search, setSearch] = useState("");
  const [product, setProduct] = useState({
    productDetails: {},
    handle: "",
  });
  const [updateStatus, setUpdateStatus] = useState("");
  const [displayStatus, setDisplayStatus] = useState("none");
  const [resetStatus, setResetStatus] = useState("none");
  const [productVideo, setProductVideo] = useState({
    productVideoDetails: {},
    handle: "",
  });
  const [productMedia, setProductMedia] = useState({
    productMediaDetails: {},
    handle: "",
  });
  const [{ searchLoader, createLoader }, setLoader] = useState(false);
  const gridApi = useRef();

  const handleChange = (e) => {
    if (e.target.value?.length) {
      const value = e.target.value.trim();
      setSearch(value);
    } else setSearch("");
  };

  const handleSearch = () => {
    if (search.length) {
      setLoader((prevState) => ({
        ...prevState,
        searchLoader: true,
      }));

      setDisplayStatus("block");
      setResetStatus("block");

      setFormData({});
      setVideo({});
      setImage({});

      setVideoAlt({});
      setImageAlt({});

      setProductMediaMetafieldId({});
      setData({});
      setUpdateStatus("");
      setProductVideo({
        productVideoDetails: {},
        handle: "",
      });
      setProduct({
        productDetails: {},
        handle: "",
      });
      setProductMedia({
        productMediaDetails: {},
        handle: "",
      })

      let dataimage = [];
      let datavideo = [];
      let datamedia = [];

      //let imageAlt = [];
      //let videoAlt = [];

      let productmediametafield = [];
      axios
        .get("/api/productByHandle", { params: { handle: search } })
        .then((response) => response.data)
        .then(
          (result) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));

            const { success, data } = result;
            const { productByHandle } = result;

            if (productByHandle) {
              if (Object.keys(productByHandle).length > 0) {
                productByHandle.metafield.value.split(",").map(
                  (value, index) => {
                    dataimage.push({
                      src: value,
                      type: "image",
                      state: "offline",
                      sort: null,
                      alt: ''
                    })
                  }
                )
                setImage(dataimage);
              }
              setProductId(productByHandle.id);

              setProduct({
                productDetails: productByHandle,
                handle: productByHandle.handle,
              });

 
            }
          },
          (error) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));
            console.log(error);
            gridApi.current.setRowData([]);
            setProduct({
              productDetails: {},
              handle: "",
            });
          }
        );

      axios
        .get("/api/damAltText", { params: { handle: search } })
        .then((response) => response.data)
        .then(
          (result) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));

            const { success, data } = result;
            const { productByHandle } = result;

            if (productByHandle) {
              if (Object.keys(productByHandle).length > 0) {

                const altText = productByHandle.metafield.value.split("|");
                 
                setImageAlt(altText);

              }
            }
          },
          (error) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));
            console.log(error);
            gridApi.current.setRowData([]);
          }
        );
        
      axios
        .get("/api/productVideoByHandle", { params: { handle: search } })
        .then((response) => response.data)
        .then(
          (result) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));

            const { success, data } = result;
            const { productByHandle } = result;

            if (productByHandle) {
              if (Object.keys(productByHandle).length > 0) {
                productByHandle.metafield.value.split(",").map(
                  (value, index) => {
                    datavideo.push({
                      src: value,
                      type: "video",
                      state: "offline",
                      sort: null,
                      alt: ''
                    })
                  }
                )
                setVideo(datavideo);

              }

              setProductVideo({
                productVideoDetails: productByHandle,
                handle: productByHandle.handle,
              }); 
            }
          },
          (error) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));
            console.log(error);
            gridApi.current.setRowData([]);
            setProductVideo({
              productVideoDetails: {},
              handle: "",
            });
          }
        );

      axios
        .get("/api/videoAltText", { params: { handle: search } })
        .then((response) => response.data)
        .then(
          (result) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));

            const { success, data } = result;
            const { productByHandle } = result;

            if (productByHandle) {
              if (Object.keys(productByHandle).length > 0) {

                const altText = productByHandle.metafield.value.split("|");
                 
                setVideoAlt(altText);

              }
            }
          },
          (error) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));
            console.log(error);
            gridApi.current.setRowData([]);
          }
        );

      axios
        .get("/api/productMedia", { params: { handle: search } })
        .then((response) => response.data)
        .then(
          (result) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));

            const { success, data } = result;
            const { productByHandle } = result;

            if (productByHandle) {
              if (Object.keys(productByHandle).length > 0) {

                  JSON.parse(productByHandle.metafield.value).map(
                  (media, index) => {
                    datamedia.push({
                      src: media['src'],
                      type: media['type'],
                      state: media['state'],
                      sort: (!media['sort'] ? null : parseInt(media['sort'])),
                      alt: media['alt']
                    })
                  }
                )
                setMediaData(datamedia);
                
                setProductMediaMetafieldId(productByHandle.metafield.id);

                setProductMedia({
                  productMediaDetails: productByHandle,
                  handle: productByHandle.handle,
                });

                setDisplayStatus("block");
                setResetStatus("none");


              }

            }
          },
          (error) => {
            setLoader((prevState) => ({
              ...prevState,
              searchLoader: false,
            }));
            console.log(error);
            gridApi.current.setRowData([]);
            setProductMedia({
              productMediaDetails: {},
              handle: "",
            });
          }
        );
    }
  };



  const handleClear = () => {
    setSearch("");
    setProduct({
      productDetails: {},
      handle: "",
    });
    gridApi.current.setRowData([]);
  };

  const [productId, setProductId] = useState({});
  const [alt_0, setAlt_0] = useState({});

  const [productMediaMetafieldId, setProductMediaMetafieldId] = useState({});
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});




  const updateImageAlt = (e, index)=> { 
    if(imageAlt.length > 0) {
      imageAlt[index] = e.target.value;
    }
    if(mediaData.length > 0) {
      mediaData[index]['alt'] = e.target.value;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });     
  };

  const updateVideoAlt = (e, index)=> { 
    if(videoAlt.length > 0) {
      videoAlt[index] = e.target.value;
    }
    if(mediaData.length > 0) {
      mediaData[index]['alt'] = e.target.value;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value }); 
  };


  const updateData = (e)=> { 
    setData({ ...data })     
    setFormData({ ...formData, [e.target.name]: e.target.value })     
  };

  
  const submitExisting = e => {
    e.preventDefault();
    let data=[...mediaData];

    Object.keys(data).forEach(key => {
 
        if(typeof formData[key+"_sort"] != 'undefined' && formData[key+"_sort"] != null){
          data[key]['sort'] = Number(formData[key+"_sort"]);
        }
        if(typeof formData[key+"_alt"] != 'undefined' && formData[key+"_alt"] != null){
          data[key]['alt'] = formData[key+"_alt"];
        }
        if(typeof formData[key+"_state_existing"] != 'undefined' && formData[key+"_state_existing"] != null){
          data[key]['state'] = formData[key+"_state_existing"];
        }
 
    });

    let productMediaId = productId.replace("gid://shopify/Product/", "");
    let productMetafieldId = 0;
    if(productMediaMetafieldId.length > 0){
       productMetafieldId = productMediaMetafieldId.replace("gid://shopify/Metafield/", "");
    }
    if(Number(productMetafieldId) > 0){
      const metafieldPayload = {
                              "metafield":{
                                "id": Number(productMetafieldId),
                                "value":`${JSON.stringify(data)}`,
                                "type":"json"
                              }
                            }
  
      axios
      .post("/api/updateProductMedia", metafieldPayload )
      .then((response) => response.data)
      .then(
        (result) => {
          const { success, data } = result;
          const { productByHandle } = result;
              setUpdateStatus("Product media data updated.");
        },
        (error) => {
          console.log(error);
          setUpdateStatus("Error");
        }
      );
    }

  };

  const submit = e => {
    e.preventDefault();

    let data =[];
  if(video.length>0){
     data=[...image,...video];
  }
  else {
     data=[...image];
  }


  let imgindex = 0;
  Object.keys(data).forEach(key => {
    if(data[key]['type'] == "image"){
      data[key]['sort'] = (!formData[key+"_sort"] ? null : parseInt(formData[key+"_sort"]));
      data[key]['alt'] = (!imageAlt[key] ? formData[key+"_alt"] : imageAlt[key]);
      data[key]['state'] = (!formData[key+"_state"] ? "offline" : formData[key+"_state"]);
      imgindex++;
    }else if(data[key]['type'] == "video"){
      data[key]['sort'] = (!formData[(key-imgindex)+"_sort_video"] ? null : parseInt(formData[(key-imgindex)+"_sort_video"]));
      data[key]['alt'] = (!videoAlt[(key-imgindex)] ? formData[(key-imgindex)+"_alt_video"] : videoAlt[(key-imgindex)]);
      data[key]['state'] = (!formData[(key-imgindex)+"_state_video"] ? "offline" : formData[(key-imgindex)+"_state_video"]);   
    }
  });
    setData(data);

    let productMediaId = productId.replace("gid://shopify/Product/", "");
    let productMetafieldId = 0;
    if(productMediaMetafieldId.length > 0){
       productMetafieldId = productMediaMetafieldId.replace("gid://shopify/Metafield/", "");
    }
    if(Number(productMetafieldId) > 0){
      const metafieldPayload = {
                              "metafield":{
                                "id": Number(productMetafieldId),
                                "value":`${JSON.stringify(data)}`,
                                "type":"json"
                              }
                            }
  
      axios
      .post("/api/updateProductMedia", metafieldPayload )
      .then((response) => response.data)
      .then(
        (result) => {
          const { success, data } = result;
          const { productByHandle } = result;
              setUpdateStatus("Product media data updated.");
        },
        (error) => {
          console.log(error);
          setUpdateStatus("Error");
        }
      );
    }
    else{

      const metafieldPayload = {
          "metafield":{
            "namespace":"Salsify",
            "key":"Product_Media_Json",
            "value":`${JSON.stringify(data)}`,
            "type":"json",
            "owner_id": Number(productMediaId),
            "owner_resource": "product" 
          }
        }
        axios
          .post("/api/createProductMedia", metafieldPayload )
          .then((response) => response.data)
          .then(
            (result) => {
              const { success, data } = result;
              const { productByHandle } = result;
                  setUpdateStatus("Product media data saved.");
            },
            (error) => {
              console.log(error);
              setUpdateStatus("Error");
            }
          );

    }
  }

  const resetMedia = e => { 
     setDisplayStatus("none");
     setResetStatus("block");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.name === "search") {
      handleSearch();
      // else if (e.target.name === "createReturn") handleCreateReturn();
    }
  };

  return (
    <div className="mediaMenuWrapper" style={{padding: "5px"}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            className="mr-4"
            name="search"
            type="text"
            style={{ borderRadius: "4px", height: "30px" }}
            value={search}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter the SKU/Handle"
          />
          <button
            className="btn btn-primary mr-4"
            style={{ fontSize: "14px", width: "70px" }}
            disabled={!search || searchLoader}
            onClick={handleSearch}
          >
            {searchLoader ? <Spinner animation="border" /> : "Search"}
          </button>
        </div>

      </div>
      <div style={{ width: "100%" }}> 
          
      {Object.keys(productMedia.productMediaDetails).length > 0 ? (
          <>
            <div>
              <div
                id= "mediaTableExisting"
                className="mediaDetailsTable"
                style={{ padding: "10px", display: `${displayStatus}` }}
              >
                <form id="mediaFormExisting" onSubmit={submitExisting} >
                
                <div>
                  <p><strong>SKU: {productMedia.productMediaDetails.handle ? productMedia.productMediaDetails.handle : ""}</strong></p>
                  <p><strong>Product Title: {productMedia.productMediaDetails.title ? productMedia.productMediaDetails.title : ""}</strong></p>
                </div>
                <table width="100%" cellPadding={5} style={{border:"1px solid #ccc", marginTop:"5px", marginBottom:"5px"}}>
                  <tr>
                    <th>S.No</th>
                    <th>Media</th>
                    <th>Alt</th>
                    <th>Sort</th>
                    <th>Action</th>
                  </tr>
                  <tr>
                    <td>Existing Media</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                      
                
                {mediaData.map(
                    (media,index)=>{
                      let key_index = index;
                      let index_src = `${index}_src`;
                      let index_alt = `${index}_alt`;
                      let index_sort = `${index}_sort`;
                      let index_state = `${index}_state_existing`;
                      let index_type = `${index}_type`;
                      let mediaThumb =  <img src={media['src']} width="220px" height="auto" />;
                      if (media['type'] == "video"){
                        mediaThumb = <video width="220" height="140" controls><source src={media['src']} type="video/mp4" /></video>;
                      }
                      let mediaStateOptions = <select id={index_state} name={index_state} onChange={updateData} style={{padding: "1px 4px", marginLeft: "5px" }} ><option value="offline">Status</option><option value="online">Online</option><option value="offline" selected>Offline</option></select>;
                      if (media['state'] == "online"){
                        mediaStateOptions = <select id={index_state} name={index_state} onChange={updateData} style={{padding: "1px 4px", marginLeft: "5px" }} ><option value="offline">Status</option><option value="online" selected>Online</option><option value="offline">Offline</option></select>;
                      }
                      return( 
                        <tr key={index} class={index} >
                          <td>
                            {++index}
                          </td>
                          <td> {mediaThumb}
                          <input class="img-index" name={index_src} type="hidden" onInput={updateData} value={media['src']} />
                          </td>
                          <td>
                          <input class="img-index"  name={index_alt} placeholder="Alt text" value={media['alt']} onChange={(e) => updateImageAlt(e, key_index)} style={{ borderRadius: "4px", width: "200px"}} /><br />
                          </td>
                          <td>
                          <input class="img-index"  name={index_sort} placeholder={media['sort']} onChange={updateData} style={{ borderRadius: "4px", width: "100px"}} />
                          </td>
                          <td>
                            {mediaStateOptions}
                          <input class="img-index" name={index_type} type="hidden" onInput={updateData} value="image" />
                          </td>
                        </tr>
                      )
                      
                    }
                  )
                }
                </table>

                <button type="submit" class="btn btn-primary mr-4" style={{ fontSize: "14px" }}>Save</button>
                <button type="button" class="btn btn-primary mr-4" style={{ fontSize: "14px" }} onClick={resetMedia}>Reset</button>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div className="noRecordsNote"></div>
        )}

        { Object.keys(product.productDetails).length > 0 ? (
          <>
            <div>
              <div
                id="mediaTableSalsify"
                className="mediaDetailsTable"
                style={{ padding: "10px", display: `${resetStatus}` }}
              >
                <form id="mediaForm" onSubmit={submit} >
                
                <div>
                  <p><strong>SKU: {product.productDetails.handle ? product.productDetails.handle : ""}</strong></p>
                  <p><strong>Product Title: {product.productDetails.title ? product.productDetails.title : ""}</strong></p>
                </div>
                <table width="100%" cellPadding={5} style={{border:"1px solid #ccc", marginTop:"5px", marginBottom:"5px"}}>
                  <tr>
                    <th>S.No</th>
                    <th>Media</th>
                    <th>Alt</th>
                    <th>Sort</th>
                    <th>Action</th>
                  </tr>
                  <tr>
                    <td>Images from Salsify</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                     {product.productDetails.metafield.value.split(",").map(
                        (value,index)=>{
                          let key_index = index;
                          let index_src = `${index}_src`;
                          let index_alt = `${index}_alt`;
                          let index_sort = `${index}_sort`;
                          let index_state = `${index}_state`;
                          let index_type = `${index}_type`;

                          return( 
                            <tr key={index} class={index} >
                              <td>
                                {++index}
                              </td>
                              <td><img src={value} width="220px" height="auto" />
                              <input class="img-index" name={index_src} type="hidden" onInput={updateData} value={value} />
                              </td>
                              <td>
                              <input class="img-index" placeholder="Alt text" value={imageAlt[key_index]} name={index_alt} onChange={(e) => updateImageAlt(e, key_index)} style={{ borderRadius: "4px", width: "200px"}} /><br />
                              </td>
                              <td>
                              <input class="img-index" placeholder="Sort order" name={index_sort} onChange={updateData} style={{ borderRadius: "4px", width: "100px"}} />
                              </td>
                              <td>
                              <select name={index_state} onChange={updateData} style={{padding: "1px 4px", marginLeft: "5px" }} >
                                <option value="offline">Status</option>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                              </select>
                              <input class="img-index" name={index_type} type="hidden" onInput={updateData} value="image" />
                              </td>
                            </tr>
                            )
                        }
                      )}

                      {Object.keys(productVideo.productVideoDetails).length > 0 ? (
                        <>
                        <tr>
                          <td>Videos from Salsify</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {productVideo.productVideoDetails.metafield.value.split(",").map(
                          (value,index)=>{
                            let key_index = index;
                            let index_src = `${index}_src_video`;
                            let index_alt = `${index}_alt_video`;
                            let index_sort = `${index}_sort_video`;
                            let index_state = `${index}_state_video`;
                            let index_type = `${index}_type_video`;
                            return( 
                              <tr key={index} class={index} >
                                <td>
                                  {++index}
                                </td>
                                <td>
                                  <video width="220" height="140" controls >
                                    <source src={value} type="video/mp4" />
                                  </video>
                                </td>
                                <td>
                                <input class="video-index" placeholder="Alt text" value={videoAlt[key_index]} name={index_alt} onChange={(e) => updateVideoAlt(e, key_index)} style={{ borderRadius: "4px", width: "200px"}} /><br />
                                </td>
                                <td>
                                <input class="video-index" placeholder="Sort order" name={index_sort} onChange={updateData} style={{ borderRadius: "4px", width: "100px"}} />
                                </td>
                                <td>
                                <select name={index_state} onChange={updateData} style={{padding: "1px 4px", marginLeft: "5px" }} >
                                  <option value="offline">Status</option>
                                  <option value="online">Online</option>
                                  <option value="offline">Offline</option>
                                </select>
                                </td>
                              </tr>
                            )
                          }
                        )}
                        </>
                      ) : ( <div></div> ) }
                      
                </table>
                <button type="submit" class="btn btn-primary mr-4" style={{ fontSize: "14px" }}>Save</button>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div className="noRecordsNote">No Records Found</div>
        )}
        
      </div>
      <div style={{padding: "5px"}}><strong>{updateStatus}</strong></div>
    </div>
  );
}

export default MediaFiles;

