import React, { useEffect, useState } from "react";
import {
  Page,
  Layout,
  Card,
  ResourceList,
  ResourceItem,
} from "@shopify/polaris";

import { useSettings } from "../AppContext";

export default function Dashboard() {
  const { customApp } = useSettings();

  const [list, setList] = useState([]);

  const setDisplay = () => {
    if (customApp === undefined || !customApp || customApp.length === 0) {
      return;
    }

    const list = [];

    if (customApp.includes("showStudents")) {
      list.push({
        url: "/students",
        name: "Student",
        description: "Manage Student customers registration.",
      });
    }

    if (customApp.includes("showStaff")) {
      list.push({
        url: "/staff",
        name: "Staff",
        description: "Manage Staff customers registration.",
      });
    }

    if (customApp.includes("showNP")) {
      list.push({
        url: "/national",
        name: "National Partners",
        description: "Manage National Partners customers registration.",
      });
    }

    if (customApp.includes("showPartnerUsers")) {
      list.push({
        url: "/partnerusers",
        name: "Partner Users",
      });
    }

    if (customApp.includes("showRetireeUsers")) {
      list.push({
        url: "/retireeusers",
        name: "Retiree Users",
      });
    }

    if (customApp.includes("showEvents")) {
      list.push({
        url: "/events",
        name: "Events",
      });
    }

    if (customApp.includes("showMetafieldDisplayNames")) {
      list.push({
        url: "/salsifyDisplay",
        name: "Metafield Display Names",
      });
    }

    if (customApp.includes("showInviteUsers")) {
      list.push({
        url: "/inviteusers",
        name: "Goldilocks Users",
      });
    }

    if (customApp.includes("showBulkPrice")) {
      list.push({
        url: "/bulkprice",
        name: "Bulk Price",
      });
    }

    if (customApp.includes("showPriceControl")) {
      list.push({
        url: "/pricecontrol",
        name: "Price Control",
      });
    }

    if (customApp.includes("showCoupons")) {
      list.push({
        url: "/coupons",
        name: "Coupons",
      });
    }

    if (customApp.includes("showOrderReturns")) {
      list.push({
        url: "/orderReturns",
        name: "Returns",
      });
    }

    if (customApp.includes("showProductMedia")) {
      list.push({
        url: "/productMedia",
        name: "Product Media",
      });
    }

    if (customApp.includes("showToolConnectUsers")) {
      list.push({
        url: "/toolconnectinviteusers",
        name: "ToolConnect Users",
      });
    }

    if (customApp.includes("showSolr")) {
      list.push({
        url: "/solr",
        name: "SOLR",
        description: "Run SOLR Sync.",
      });
    }

    if (customApp.includes("showBundleReport")) {
      list.push({
        url: "/bundleReport",
        name: "Bundle Report",
      });
    }

    if (customApp.includes("showSalsify")) {
      list.push({
        url: "/salsify",
        name: "Salsify",
        description: "Generate reports for Salsify fields changes.",
      });
    }

    if (customApp.includes("showEkata")) {
      list.push({
        url: "/ekata",
        name: "Ekata",
        description: "Generate Transaction Risk report with Ekata.",
      });
    }

    if (customApp.includes("showSolrFilters")) {
      list.push({
        url: "/solrFilters",
        name: "SOLR Filters",
        description: "Configure Filters using Salsify metafields.",
      });
    }

    if (customApp.includes("showPriceSpider")) {
      list.push({
        url: "/priceSpider",
        name: "PriceSpider",
        description: "Configure PriceSpider data transfer.",
      });
    }

    if (customApp.includes("showRegistrationCodes")) {
      list.push({
        url: "/registrationCodes",
        name: "Registration Codes",
        description: "Manage Registration Code for users registration.",
      });
    }

    if (customApp.includes("showCredits")) {
      list.push({
        url: "/credits",
        name: "Credits",
        description: "Manage Customers' credits and adjustments. Reports.",
      });
    }

    if (customApp.includes("showKnotReport")) {
      list.push({
        url: "/knotReport",
        name: "Knot Report",
      });
    }

    if (customApp.includes("showGiveaways")) {
      list.push({
        url: "/giveaways",
        name: "Giveaways",
        description: "Manage Giveaways. Reports.",
      });
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      item.id = i;
    }

    setList(list);
  };

  useEffect(() => {
    setDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              resourceName={{ singular: "function", plural: "functions" }}
              items={list}
              renderItem={(item) => {
                const { id, url, name, description } = item;

                return (
                  <ResourceItem
                    id={id}
                    url={url}
                    accessibilityLabel={`View details for ${name}`}
                  >
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {name}
                    </div>
                    <div>{description}</div>
                  </ResourceItem>
                );
              }}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
